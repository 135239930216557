import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NewsCard from '../common/NewsCard';

const News = () => {
  const {
    magyarEgyetem,
    fejlodik,
    demoDay,
    successfulsemester24
  } = useStaticQuery(graphql`
    query {
      magyarEgyetem: file(relativePath: { eq: "news/magyar_egyetem.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 403, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      fejlodik: file(relativePath: { eq: "news/fejlodik.png" }) {
        childImageSharp {
          fluid(maxWidth: 403, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      demoDay: file(relativePath: { eq: "news/demo_day.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 403, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      successfulsemester24: file(relativePath: { eq: "news/semester24.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 403, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const content = [
    {
      title: 'Több mint 4200 hallgatóval ismét sikeres félévet zárt a HSUP',
      body:
        'Negyedik tanévét kezdte meg a Nemzeti Innovációs Ügynökség (NIÜ) gondozásában működő Hungarian Startup University Program, amire eddig összesen 14384 egyetemi hallgató jelentkezett. Az idei tanévben minden eddiginél több, 33 intézmény csatlakozott a programhoz és 4232 hallgató vette fel a modern vállalkozói szemléletre felkészítő tantárgyat. Az idei évtől ráadásul a legjobb csapatok akár 20 millió forintos támogatásban részesülhetnek startupjuk beindításához.',
      img: successfulsemester24,
      linkTo:
        'https://www.digitalhungary.hu/start-up/Tobb-mint-4200-hallgatoval-ismet-sikeres-felevet-zart-a-HSUP/22901'
    },
    {
      title: 'Útnak indulnak a legsikeresebb egyetemi startupok',
      body:
        '20 egyetemi startup csapat mutatkozott be a 2023-as tanévet záró HSUP Demo Day-en, 2023. július 12-én Kápolnásnyéken. A Nemzeti Innovációs Ügynökség által szervezett két féléves oktatáson mintegy 4000 hallgató vett rész.  A szerdai bemutatkozáshoz 194 ötletből választották ki a legjobb csapatokat, akik befektetőkből álló szakmai zsűri előtt prezentálták az innovációs vállalkozási ötleteiket.',
      img: demoDay,
      linkTo: 'https://nkfih.gov.hu/hivatalrol/hivatal-hirei/hsup-demo-day-2023'
    },
    {
      title: 'Tovább fejlődik a Hungarian Startup University Program',
      body:
        'A Hungarian Startup University Program (HSUP) a startup ökoszisztéma dinamizálását, valamint a fiatalok vállalkozói attitűdjének erősítését szolgáló egyik legjelentősebb kezdeményezéssé nőtte ki magát az elmúlt három évben. A 2020-as indulás óta számos tapasztalat gyűlt össze a programmal kapcsolatban úgy a startup ökoszisztéma meghatározó szereplői, mint a program aktív résztvevői körében. Mindezen tapasztalatok alapján alakítottuk ki a HSUP tovább fejlesztési irányait.',
      img: fejlodik,
      linkTo:
        'https://nkfih.gov.hu/hivatalrol/hivatal-hirei/hungarian-startup-university-program-2023'
    },
    {
      title: 'Magyar egyetemi ötletek hódíthatják meg a világot',
      body:
        'Vasban, rostban és esszenciális aminosavakban gazdag tücsökliszt, távolról irányítható okos gyógyszeradagoló, jutalomrendszer mobilalkalmazás, iskolák és tanárok számára a diákok hatékony motiválásához, új generációs növénytermesztési rendszer, elsősorban szántóföldi kultúrákhoz, valamint csőhálózat és kút monitorozó robot. Csak néhány abból a 20 projektből, amit a szakértők a legígéretesebb ötletnek ítéltek a HSUP program második tanévének végén. Ezek a magyar egyetemi ötletek hódíthatják meg a világpiacokat, ezekből nőhetnek ki az évtized magyar unikornisai. Az EFOTT fesztivál kísérő rendezvényeként Kápolnásnyéken a Hungarian Startup University Program (HSUP) Demo Day eseményén most bemutatkoznak a projektcsapatok.',
      img: magyarEgyetem,
      linkTo:
        'https://nkfih.gov.hu/hivatalrol/hivatal-hirei/magyar-egyetemi-otletek'
    }
  ];

  return (
    <section className="bg-snow">
      <div className="container">
        <div className="flex flex-col p-4 pb-20 lg:justify-center">
          <h2 className="text-center py-8">Hírek</h2>
          {content.map(({ title, body, img, linkTo }) => (
            <NewsCard
              key={title}
              title={title}
              body={body}
              button
              buttonText="elolvasom"
              linkTo={linkTo}
              img={img}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default News;
