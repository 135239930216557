import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const Contact = () => {
  const { contact, partner, university } = useStaticQuery(graphql`
    query {
      contact: file(relativePath: { eq: "illu_contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      partner: file(relativePath: { eq: "illu_partner.png" }) {
        childImageSharp {
          fluid(maxWidth: 782, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      university: file(relativePath: { eq: "illu_university.png" }) {
        childImageSharp {
          fluid(maxWidth: 782, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const content = [
    {
      href: 'mailto:info.hsup@niu.hu?subject=Partner - partner neve',
      title: 'Partnereknek',
      text:
        'Ön is segítené a fiatal tehetségek munkáját? Csatlakozzon hozzánk!',
      img: partner
    },
    {
      href:
        'mailto:info.hsup@niu.hu?subject=Oktatási intézmény - intézmény neve',
      title: 'Oktatási intézményeknek',
      text:
        'Új lehetőségeket biztosítana hallgatóinak, hogy jobban megismerjék az innováció világát? Indítsa el a Hungarian Startup University Programot!',
      img: university
    }
  ];

  return (
    <section className="border-t-4 border-primary py-16" id="contact">
      <div className="container">
        <div className="flex my-4 flex-col lg:flex-row justify-center items-center">
          <div className="p-4 lg:w-7/12">
            <h2 className="text-center lg:text-left pb-8">Kapcsolat</h2>
            {content.map(({ href, title, text, img }) => (
              <a
                href={href}
                className="container p-4 sm:p-2 flex flex-row rounded border-2 hover:border-gray-500 mb-4 transition duration-200"
              >
                <Img
                  className="hidden sm:flex w-2/12"
                  fluid={img.childImageSharp.fluid}
                  style={{
                    background:
                      'linear-gradient(180deg, #F5F6F9 0%, rgba(245, 246, 249, 0.0001) 100%)'
                  }}
                />
                <div className="sm:ml-4 w-10/12">
                  <h4 className="font-semibold mb-1">{title}</h4>
                  <p className="text-gray-600">{text}</p>
                </div>
              </a>
            ))}
          </div>
          <div className="w-full p-4 sm:w-5/12 lg:ml-20">
            <Img
              alt="Vedd fel velünk a kapcsolatot!"
              fluid={contact.childImageSharp.fluid}
              className="mb-4"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
