import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Card } from '../common';
import Link from '../common/Link';

const WhatsMore = () => {
  const { scholarship, credit, mentoring, challenges } = useStaticQuery(graphql`
    query {
      scholarship: file(relativePath: { eq: "illu_scholarship.png" }) {
        childImageSharp {
          fluid(maxWidth: 568, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      credit: file(relativePath: { eq: "illu_credit.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mentoring: file(relativePath: { eq: "illu_mentoring.png" }) {
        childImageSharp {
          fluid(maxWidth: 566, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      challenges: file(relativePath: { eq: "illu_p_challenges.png" }) {
        childImageSharp {
          fluid(maxWidth: 566, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const content = [
    {
      title: 'Kredit',
      body:
        'Tudjuk, hogy most az egyetem sikeres elvégzése az egyik legfontosabb célod. A HSUP ebben is segíthet, hiszen ez a program egyetemi tárgyként indul, azaz az oktatási intézményed innovatív szemléletének köszönhetően kreditet kaphatsz a HSUP sikeres elvégzéséért.',
      img: credit
    },
    {
      title: 'Ösztöndíj',
      body:
        'Ha sikeresen teljesítetted az első félévet, és a második félévben is folytatod a tárgyat – azaz csapatban dolgozol tovább egy üzleti ötleten – HSUP-ösztöndíjban részesülsz. Havi 50 ezer forint támogatással segítünk abban, hogy ötleteddel felkészülj a valós piaci környezetre, és csak az álmaidra fókuszálhass! A második (tavaszi) szemeszter során legjobban teljesítő 15 csapat tagjai – csapattagonként – 1 millió forintos külön ösztöndíjban részesülnek. A startuppá válni képes 4 legígéretesebb csapat pedig – amennyiben megalapítják a vállalkozásukat és sikeresen vonnak be tőkét a piacról a tavaszi félév zárását követően – további vissza nem térítendő támogatáshoz juthatnak, startuponként akár 20 millió forint értékben.',
      img: scholarship
    },
    {
      title: 'Mentorálás',
      body:
        'Minden támogatást megkapsz – és hogy igazán értékessé és egyedivé tegyük a programot, az egyetem oktatóin kívül a startup világ vállalkozóit is segítségül hívtuk. Ijesztőnek hangzik? Csak semmi pánik! Startupperként van, hogy bejön a számításunk, és van, hogy nem úgy alakulnak a dolgok, ahogy szeretnénk. Szerencsére a HSUP-ban a fejlődés számít!',
      img: mentoring
    },
    {
      title: 'Partneri kihívások',
      body:
        'A partneri kihívás során a partnerek olyan kihívást vagy problémát fogalmaznak meg, amiket a mindennapi működésük során tapasztalnak. Az első félévben dönthettek, hogy saját ötletet bemutató onepagert adtok le, vagy egy partner által megfogalmazott vállalati kihívásra dolgoztok ki megoldást. A kihívást adó partner átnézi és értékeli a beérkezett ötleteket, majd kiválasztja, azt az ötletet, amely kidolgozását szeretné támogatni mentorációval és egyéb szakmai felajánlással a második félév során. A partner által kiválasztott ötleten dolgozó csapat is (1-3 fős) ösztöndíj- támogatásban fog részesülni a többi, saját projektötleten dolgozó csapathoz hasonlóan. A HSUP platformon a partneri kihívások folyamatosan nyílnak meg a félév során, így ott tudtok majd a részletekről tájékozódni, és jelentkezni a kihívásra.',
      img: challenges
    }
  ];

  return (
    <section className="bg-snow">
      <Link to="moreCards">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="96"
          height="96"
          fill="none"
          viewBox="0 0 96 96"
          className="mx-auto"
          style={{ transform: 'translateY(-50%)' }}
        >
          <circle
            cx="48"
            cy="48"
            r="45"
            fill="#fff"
            stroke="#F5F6F9"
            strokeWidth="6"
          />
          <path
            fill="#0E1655"
            fillRule="evenodd"
            d="M48 28c-11.04 0-20 8.96-20 20s8.96 20 20 20 20-8.96 20-20-8.96-20-20-20zm0 4c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zm-8 14l8 8 8-8H40z"
            clipRule="evenodd"
          />
        </svg>
      </Link>
      <div className="container" id="moreCards">
        <h2 className="text-center">Mit kaphatsz még?</h2>
        <div className="flex flex-col p-4 pb-20 lg:justify-center">
          {content.map(({ title, body, img }) => (
            <Card key={title} title={title} body={body} img={img} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatsMore;
