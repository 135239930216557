import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Testimonial = () => {
  const { kantor, balogh, novak, kiri, lorinczi, eva } = useStaticQuery(graphql`
    query {
      kantor: file(relativePath: { eq: "testimonial/kantor.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      balogh: file(relativePath: { eq: "testimonial/balogh.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      novak: file(relativePath: { eq: "testimonial/novak.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      kiri: file(relativePath: { eq: "testimonial/kiri.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      lorinczi: file(relativePath: { eq: "testimonial/lorinczi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      eva: file(relativePath: { eq: "testimonial/eva.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  const images = [
    {
      name: 'Balogh Petya',
      university: 'Baconsult inkubátor alapító, befektető',
      text:
        '"Az innováció nem más, mint kipróbálni valamit, amit még nem próbáltunk és nem tudjuk biztosan, hogy mi lesz belőle. Legtöbbször persze semmi jó. De ha megszokjuk, megszeretjük ezt a fajta kísérletezést, ha megtanuljuk túlélni a kudarcot, akkor előbb-utóbb sikerülni fog. A HSUP-val elkezdheted ezt a kísérletezést! Hajrá!"',
      image: balogh,
      alt: 'alttext'
    },
    {
      name: 'Kántor Kristóf',
      university: 'H.O.R.U.S. feltaláló és fejlesztő',
      text:
        '"Fontosnak tartom, hogy a mi generációnk a lehető leghamarabb találkozzon az innovációval. A HSUP egy könnyen elérhető, hiánypótló és gyakorlatias megoldás a startup világ megismerésére. A legfontosabb, hogy bízz magadban és az ötletedben, akkor bármi lehetséges. A HSUP első lépés lehet arra, hogy akár saját céget építs az innovatív ötletedre."',
      image: kantor,
      alt: 'alttext'
    },
    {
      name: 'Kiri Hunor',
      university: 'ReFilamer',
      text:
        '“Innovátor az, aki a lehetetlenből lehetségest, a kudarcból tapasztalatot, a problémából pedig lehetőséget kovácsol. Legyél Te vagy a jövő innovátora, a HSUP segít ebben!”',
      image: kiri,
      alt: 'alttext'
    },
    {
      name: 'Lőrinczi Ede',
      university: 'Kontekt',
      text:
        '“A sikeres startupperek számomra az üzleti élet rocksztárjai, mert nagyon sok kockázatvállalással, munkával és iterálással robbanásszerű növekedést tudnak a semmiből elérni. A HSUP-t is egy ilyen sikeres startupnak tekintem, aki sikeresen újította meg a gyakorlati oktatást Magyarországon, és indított el minket is a vállalkozóvá válás útján.”',
      image: lorinczi,
      alt: 'alttext'
    },
    {
      name: 'Éva Gergő',
      university: 'Scraping Robotics',
      text:
        '“A HSUP nagyszerű alap és ugródeszka egy startup elindításához. Mire befejeztük a programot, volt konkrét tervünk, találtunk befektetőt és már nem csak ötleteltünk, hanem cégként folytathattuk a fejlesztést.”',
      image: eva,
      alt: 'alttext'
    },
    {
      name: 'Novák Blanka',
      university:
        'Drop the STEM podcast, STEMPOOL és SynthEthics alapítója. Fiatal kutató',
      text:
        '"Légy katalizátor, mely gyorsabbá teszi a reakciót azáltal, hogy alternatív útvonalat prezentál. A hatékonyságot megnövelheted, ha reakciópartnerként szerepel a globális együttműködés innovációd egyenletében. Ebben lehet segítségedre a HSUP."',
      image: novak,
      alt: 'alttext'
    }
  ];
  return (
    <section className="bg-snow">
      <div className="container flex pt-16 flex-col justify-center items-center">
        <h2 className="text-center pb-8">
          Inspirálódj a közösségből és emelkedj ki a tömegből!
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 px-4 py-16">
          {images.map(({ name, university, text, image, alt }) => (
            <div
              className="container flex flex-col w-full max-w-sm sm:w-96  border rounded-lg p-6 pt-0 border-gray-300 bg-white"
              key={name}
            >
              <div className="flex flex-row items-center">
                <Img
                  className="object-cover flex-none min-w-16 min-h-16 w-16 h-16 rounded-full border-2 border-secondary"
                  alt={alt}
                  fluid={image.childImageSharp.fluid}
                />
                <div className="m-4" style={{ maxWidth: 240 }}>
                  <h3 className="text-lg font-black">{name}</h3>
                  <h4 className="text-base text-secondary font-normal">
                    {university}
                  </h4>
                </div>
              </div>
              <p className="text-base text-coolGray-700 font-thin">{text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
