import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Partners = () => {
  const {
    allatorvosi,
    bge,
    dunaujvaros,
    obuda,
    pecs,
    szeged,
    ejf,
    gfe,
    nyiregyhazi,
    eszterhazy,
    metu,
    tancmuveszeti,
    debreceni,
    szechenyi,
    neumann,
    miskolc,
    kozszolgalati,
    pannon,
    mome,
    muszaki,
    elte,
    kepzo,
    corvinus,
    kje,
    mate,
    semmelweis,
    soe,
    te,
    gdf,
    mf,
    the,
    edutus
  } = useStaticQuery(graphql`
    query {
      allatorvosi: file(relativePath: { eq: "logo/allatorvosi.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      bge: file(relativePath: { eq: "logo/bge.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      dunaujvaros: file(relativePath: { eq: "logo/dunaujvaros.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      obuda: file(relativePath: { eq: "logo/obuda.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      pecs: file(relativePath: { eq: "logo/pecs.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      szeged: file(relativePath: { eq: "logo/szeged.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      nyiregyhazi: file(relativePath: { eq: "logo/nyiregyhazi.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      eszterhazy: file(relativePath: { eq: "logo/eszterhazy.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      metu: file(relativePath: { eq: "logo/metu.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      tancmuveszeti: file(relativePath: { eq: "logo/tancmuveszeti.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      debreceni: file(relativePath: { eq: "logo/debreceni.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      szechenyi: file(relativePath: { eq: "logo/szechenyi.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      neumann: file(relativePath: { eq: "logo/neumann3.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      miskolc: file(relativePath: { eq: "logo/miskolc.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      kozszolgalati: file(relativePath: { eq: "logo/kozszolgalati.jpg" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      kepzo: file(relativePath: { eq: "logo/kepzo.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      pannon: file(relativePath: { eq: "logo/pannon.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      mome: file(relativePath: { eq: "logo/mome.jpg" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      muszaki: file(relativePath: { eq: "logo/muszaki.jpg" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      elte: file(relativePath: { eq: "logo/elte.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      corvinus: file(relativePath: { eq: "logo/corvinus.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      kje: file(relativePath: { eq: "logo/kje.jpg" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      mate: file(relativePath: { eq: "logo/mate.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      semmelweis: file(relativePath: { eq: "logo/semmelweis.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      soe: file(relativePath: { eq: "logo/soe.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      te: file(relativePath: { eq: "logo/te.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      gdf: file(relativePath: { eq: "logo/gdf.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      mf: file(relativePath: { eq: "logo/mf.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      the: file(relativePath: { eq: "logo/the.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      edutus: file(relativePath: { eq: "logo/edutus.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      ejf: file(relativePath: { eq: "logo/ejf.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      gfe: file(relativePath: { eq: "logo/gfe.png" }) {
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const images = [
    ejf,
    gfe,
    allatorvosi,
    bge,
    dunaujvaros,
    obuda,
    pecs,
    szeged,
    nyiregyhazi,
    eszterhazy,
    metu,
    tancmuveszeti,
    debreceni,
    szechenyi,
    neumann,
    miskolc,
    kozszolgalati,
    pannon,
    mome,
    muszaki,
    elte,
    kepzo,
    corvinus,
    kje,
    mate,
    semmelweis,
    soe,
    te,
    gdf,
    mf,
    the,
    edutus
  ];

  return (
    <section>
      <div className="flex lg:w-full py-4 flex-row flex-wrap justify-center">
        {images.map(img => (
          <Img
            fixed={img.childImageSharp.fixed}
            className="object-cover rounded m-4 xl:m-6"
          />
        ))}
      </div>
    </section>
  );
};

export default Partners;
